<template>
    <CKEditor v-model="editorData" :editor="classicEditor" :config="editorConfig" />
</template>

<script>
import { computed, defineComponent } from 'vue';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code';
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Table from '@ckeditor/ckeditor5-table/src/table';

export default defineComponent({
    name: 'CustomWyswig',
    props: {
        modelValue: {
            type: null,
        },
        ckConfig: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(props, context) {
        const editorData = computed({
            get: () => (props.modelValue ? props.modelValue : ''),
            set: (value) => context.emit('update:modelValue', value),
        });
        const classicEditor = ClassicEditor;
        const defaultConfig = {
            // cloudServices: {
            //   tokenUrl: '',
            //   uploadUrl: '',
            // },
            plugins: [
                Font,
                EssentialsPlugin,
                BoldPlugin,
                UnderlinePlugin,
                StrikethroughPlugin,
                ItalicPlugin,
                LinkPlugin,
                ParagraphPlugin,
                CodePlugin,
                SubscriptPlugin,
                SuperscriptPlugin,
                EasyImagePlugin,
                ImagePlugin,
                ImageUploadPlugin,
                CloudServicesPlugin,
                Heading,
                HeadingButtonsUI,
                Highlight,
                SourceEditing,
                SimpleUploadAdapter,
                GeneralHtmlSupport,
                ListStyle,
                Table,
            ],
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                ],
            },
            toolbar: {
                items: [
                    'heading',
                    'fontSize',
                    'fontColor',
                    'fontBackgroundColor',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'code',
                    'subscript',
                    'superscript',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'undo',
                    'redo',
                    'imageUpload',
                    'highlight',
                    'sourceEditing',
                ],
            },
            simpleUpload: {
                uploadUrl: 'http://new-volgaline-backend-php.localhost',
                withCredentials: true,
                headers: {
                    'X-CSRF-TOKEN': 'CSRF-Token',
                    Authorization: localStorage.getItem('access_token'),
                },
            },
        };
        const editorConfig = Object.assign({}, defaultConfig, props.config);

        const initDocumentEditor = (editor) => {
            const toolbarContainer = document.querySelector('.document-editor__toolbar');
            if (toolbarContainer) {
                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
            }
            const win = window;
            win.editor = editor;
        };

        return {
            classicEditor,
            editorConfig,
            editorData,
            initDocumentEditor,
        };
    },
});
</script>

<style scoped></style>
